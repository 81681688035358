<template>
  <div class="equipment-maintain-wrapper">
    <van-nav-bar
      title="保养计划"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="equipment-maintain-form">
      <van-popover
        v-model="showPopover"
        trigger="click"
        :actions="types"
        placement="right-start"
        @select="onSelect"
      >
        <template #reference>
          <span class="doctype">{{ text }} <van-icon name="arrow-down"/></span>
        </template>
      </van-popover>
      <span>共{{ total }}条数据</span>
    </div>
    <div class="equipment-maintain-box">
      <van-empty
        v-if="!loading && list.length == 0"
        :image="emptyImage"
        description="暂无数据"
      />
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <div v-for="item in list" :key="item.id" class="maintain-item">
          <span class="maintain-interval"
            >每<i>{{ item.maintainInterval }}</i
            >天</span
          >
          <div class="maintain-title">{{ item.project }}</div>
          <div class="maintain-bottom">
            <span class="maintain-mode">{{ item.typeName }}</span>
            <span class="maintain-lastdate"
              >最近一次时间:{{ item.latestDate }}</span
            >
          </div>
          <van-divider />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import {
  getEquipmentMaintainPlans,
  getEquipmentMaintainType
} from "@/api/basics/equipment";
import equipmentMixin from "./equipmentMixin";
export default {
  mixins: [equipmentMixin],
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      text: "保养类型",
      query: {
        page: 1,
        size: 10,
        type: ""
      },
      list: [],
      total: 0,
      loading: false,
      finished: false,
      types: [],
      showPopover: false
    };
  },
  created() {
    this.getSelects();
  },
  methods: {
    onSelect(v) {
      console.log("v", v);
      this.text = v.text;
      // 设置类型
      this.query.type = v.value;
      // 清空历史数据
      this.list = [];
      // 回归到第一页
      this.query.page = 1;
      // 取消已经完成的状态
      this.finished = false;
      // 开始加载数据
      this.onLoad();
    },
    getSelects() {
      // 获取计划类型
      getEquipmentMaintainType().then(res => {
        this.types = res.map(a => {
          a.text = a.label;
          return a;
        });
      });
    },
    onLoad() {
      this.getData();
    },
    getData() {
      this.getEquipmentMaintainPlans();
    },
    getEquipmentMaintainPlans() {
      this.loading = true;
      getEquipmentMaintainPlans(this.id, this.query)
        .then(res => {
          console.log("res", res);
          this.loading = false;
          this.list = this.list.concat(res.list);
          if (res.list.length < this.query.size) {
            this.finished = true;
          } else {
            this.query.page++;
          }
          this.total = res.total;
        })
        .catch(e => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.equipment-maintain-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .equipment-maintain-form {
    height: 56px;
    border-bottom: 1px solid #e1e3e8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    font-size: 12px;
    color: #999;
    .doctype {
      padding: 6px;
      background-color: #f8f9f9;
      border-radius: 4px;
      color: #646464;
      display: flex;
      align-items: center;
    }
  }
  .equipment-maintain-box {
    flex: 1;
    overflow-y: auto;
    height: 0;
    padding-top: 14px;
    .maintain-item {
      position: relative;
      padding: 0 16px;
      .maintain-interval {
        position: absolute;
        right: 16px;
        height: 24px;
        width: 63px;
        background-color: #1676ff;
        color: #fff;
        text-align: center;
        border-radius: 4px;
        line-height: 24px;
        font-size: 12px;
        transform: skewX(6deg);
        i {
          font-size: 16px;
          padding: 0 2px;
          vertical-align: top;
        }
      }
      .maintain-bottom {
        margin-top: 18px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        align-items: center;
        .maintain-mode {
          background-color: rgba(254, 119, 11, 0.1);
          color: #fe770b;
          padding: 4px 8px;
          border-radius: 2px;
        }
        .maintain-lastdate {
          font-weight: 500;
        }
      }
    }
  }
}
</style>
